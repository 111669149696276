import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, faShare } from "@fortawesome/free-solid-svg-icons";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import StartRating from "components/StartRating/StartRating";
import { FrontendData, OpeningHours } from "../../../types/types";

interface BusinessDetailsProps {
  businessDetails: {
    id: number;
    business_name: string;
    address: string;
    description: string;
    rating: number;
    total_rating: number;
    status: string;
    google_link: string;
    user_id: number;
    claimed: number | null;
    business_type: number;
  };
}



const BusinessDetails: React.FC<BusinessDetailsProps> = ({ businessDetails }) => {
  const [frontendData, setFrontendData] = useState<FrontendData | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [openingHours, setOpeningHours] = useState<OpeningHours | null>(null);

  const [venueOpening, setVenueOpening] = useState<string>('');
  const [venueClosing, setVenueClosing] = useState<string>('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await fetch('/api/business/post/openinghours', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ business_id: businessDetails.user_id }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setOpeningHours(data);

      } catch (error) {
        console.error('Error fetching opening hours:', error);
      }
    };

    fetchData();
  }, [businessDetails]);

  useEffect(() => {
    if (openingHours) {
      const formatTime = (time: string) => {

        if (time === "Closed") {
          return "Closed";
        } else {
          const [hour, minute] = time.split(':').map(Number);
          const ampm = hour >= 12 ? 'PM' : 'AM';
          const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
          const formattedMinute = minute.toString().padStart(2, '0'); // Ensure minute is always two digits
          return `${formattedHour}:${formattedMinute} ${ampm}`;
        }
      };

      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const currentDay = days[new Date().getDay()];
      const dayOpen = `${currentDay}_opening` as keyof OpeningHours;
      const dayClose = `${currentDay}_closing` as keyof OpeningHours;

      const openingTime = openingHours[dayOpen];
      const closingTime = openingHours[dayClose];

      setVenueOpening(formatTime(openingTime));
      setVenueClosing(formatTime(closingTime));
    }
  }, [openingHours]);

  useEffect(() => {
    const getCookie = (name: string): string | null => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${name}=`)) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    const frontendDataCookie = getCookie('frontendData');
    if (frontendDataCookie) {

      try {
        const parsedFrontendData: FrontendData = JSON.parse(frontendDataCookie);
        setFrontendData(parsedFrontendData);
      } catch (error) {
        console.error('Error parsing frontendData cookie:', error);
      }
    } else {
      console.log('Cookie not found or has no value');
    }
  }, []);

  const renderStars = (rating: number) => {
    const stars = [];
    const roundedRating = Math.floor(rating);

    for (let i = 0; i < roundedRating; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className="text-black "
        />
      );
    }

    if (roundedRating < rating) {
      stars.push(
        <FontAwesomeIcon
          key="half-star"
          icon={faStarHalfAlt}
          className="text-black "
        />
      );
    }

    for (let i = stars.length; i < 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={`empty-star-${i}`}
          icon={faStar}
          className="text-black opacity-10"
        />
      );
    }

    return <span className="ml-1">{stars}</span>;
  };

  return frontendData ? (
    <div className={`${isMobile ? ("space-y-4") : ("space-y-4 mt-10")}`}>

      <div className="marcellus-regular text-3xl font-bold items-center flex">
        <h1 className="flex">{businessDetails.business_name}</h1>
        {businessDetails.claimed === 0 && <div className=" text-lg p-2 text-red-500 "></div>}
        {businessDetails.claimed === 1 && <div className=" text-lg p-2 text-green-400  ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </div>}
      </div>

      <div>
        {isMobile ? (
          <div>
            <div className="flex items-center">
              {businessDetails.status === "open" ? (
                <div>
                  <span className="text-green-500">Open ({venueOpening} - {venueClosing})</span>
                </div>
              ) : (
                <div>
                  <span className="text-red-400">Closed&nbsp;</span>
                  <a className="underline text-green-400" href={`/listing?type=${businessDetails.business_type}&open=open_now`} title="Booking near you salon, spa - OPEN NOW">show open now</a>
                </div>
              )}

              <div className="mx-2 p-0 my-0 bold text-4xl grid">·</div>

              <div>
                <StartRating reviewCount={businessDetails.total_rating} point={businessDetails.rating} />
              </div>

              <div></div>
            </div>
            <span className={`${isMobile ? ('mb-2') : ('')} font-thin hover:underline focus:underline`}>
              {businessDetails.google_link != null && (
                <a href={`${businessDetails.google_link}`} target="_blank" rel="noopener noreferrer"><i className="las la-map-marker-alt"></i> {`${businessDetails.address}`}, {frontendData.city_name}</a>
              )}
            </span>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between" style={{ justifyContent: "space-between" }}>
              <div>
                {businessDetails.status === "open" ? (
                  <span className="text-green-500">Open ({venueOpening} - {venueClosing})</span>
                ) : (
                 <>
                   <span className="text-red-400">Closed&nbsp;</span>
                   <a className="underline text-green-400" href={`/listing?type=${businessDetails.business_type}&open=open_now`} title="Booking near you salon, spa - OPEN NOW">show open now</a>
                   </>
                )}
                <span className={`text-black font-bold ml-3`}>{renderStars(businessDetails.rating)} {businessDetails.rating}</span>
                <span className="text-gray-600 block w-[70%]"> {`${businessDetails.address}`}, {frontendData.city_name}
                  <span className="font-semibold ml-3">
                    {businessDetails.google_link != null && (
                      <a href={`${businessDetails.google_link}`} target="_blank" rel="noopener noreferrer" className="hover:underline text-purple-600">Get Directions</a>
                    )}
                  </span></span>
              </div>
              <div className="flex">
                <button onClick={() => { if (navigator.share) { navigator.share({ title: 'Share Venue', text: 'Check out this cool website!', url: window.location.href, }).then(() => console.log('Share successful')).catch((error) => console.error('Error sharing:', error)); } }} className="p-1 pl-2 pr-2 rounded-full bg-black bg-opacity-30 hover:bg-opacity-50 text-white mr-4 cursor-pointer">
                  <FontAwesomeIcon icon={faShare} /></button>

                <BtnLikeIcon business_id={businessDetails.id} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <>Loading...</>
  );
};

export default BusinessDetails;
